<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Intraform from "./components/Intraform";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'

import Swal from "sweetalert2";
// import { required } from "vuelidate/lib/validators";
// import { fetchSettings } from "./api";

export default {

   import: {},
    page: {
        title: "Employes SH Extra DP",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: { Layout, PageHeader,Intraform,
     Dataset,
      DatasetItem,
      // DatasetInfo,
      DatasetPager,
      DatasetSearch,
      DatasetShow,},
    data() {
    return {
      selectedVisitor: undefined,
      tableData: [],
      title: "Employes SH Extra DP ",
      List_Employees: [],
      rows:'',
      active:false,
      selectedRows:[],
      Visitor:undefined,
      refrechTable:undefined,
      items: [
        {
          text: "Master Data",
          to: { name: 'master_data' }
        },
        {
          text: "Referentiels",
          to: { name: 'master_data.referentiels' }
        },
        {
          text: "Gestion Du Personnel",
          to: { name: 'master_data.referentiels.users' }
        },
        {
          text: "Employes SH Extra DP",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
      currentSelectedVisitor : null,
      cols: [
        {
          name: "Nom de Employes",
          field: "first_name",
          sort: ''
        },
        {
          name: "Carte d'identitie",
          field: "email",
          sort: ''
        },
        {
          name: "Fonction",
          field: "phone",
          sort: ''
        },
        {
          name: "Structure",
          field: "business_address",
          sort: ''
        },
      ],
      selectedIndex:-1,
    };
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.tableData.length;

    // fetch divisions from api

    this.fetchEmployeeList();
  },
  watch:{
     refrechTable:{
      handler(val){
        this.fetchEmployeeList();
        this.refrechTable=false;
      }
    },
      selectedRows(){
        if(this.selectedRows.length){
          //console.log(this.selectedRows[0]);
         this.selectedVisitor = this.selectedRows[0];
        }
        else{

          this.selectedVisitor =[];
        }
      },
      Visitor:{
          handler(val){       
            this.List_Employees.push({
                Visitor_fullname:val.Visitor_fullname,
                Visitor_identity_card_number:val.Visitor_identity_card_number,
                Visitor_function:val.Visitor_function,
                Visitor_company_id:val.Visitor_company_id,
            });
            
          }
          
          
      }
  },
  methods:{
    selectRow(row,index){
      if(this.selectedIndex == index) {this.selectedRows = []; this.selectedIndex = -1;}
      else {
        this.selectedRows = [row];
        this.selectedIndex = index;
      }
    },
    fetchEmployeeList(){
      this.$http.post('/referentiel/employee_intra/list')
      .then(res => {
        this.List_Employees = res.data.list
        console.log(this.List_Employees);
      })
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },

    getVisitor(){
      this.$http.get('/referentiel/employee_intra/list')
      .then(response => {
          this.data = response.data;
          this.selectedRows.push(response.data[0]);
      })
      .catch(error => console.log(error))
    },
    refreshVisitor(){
      this.getVisitor();
    },
     deleteVisitor(typeName, typeUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer le type :" + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/referentiel/employee_intra/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                    this.$toast.success(res.data.original.msg);
                    
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
              this.fetchEmployeeList();
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
     editVisitor(typeUid) {
      let loader = this.$loading.show();
      this.$http.post("/referentiel/employee_intra/getSingleVisitorData/" + typeUid)
        .then((res) => {
          var status = res.data.original.status;
          var visitor = res.data.original.data.visitor;
          
          loader.hide();
          switch (status) {
            case 200:
                this.currentSelectedVisitor = visitor;
                this.updateTabsUi();
            break;

            case 500:
              this.$toast.warning(res.data.original.msg);
            break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

     fetchVisitorDetails(uuid = null) {
      let loader = this.$loading.show();
      this.$http.post("/referentiel/employee_intra/getSingleVisitorData/" + uuid)
        .then((res) => {
          var status = res.data.original.status;
          var visitor = res.data.original.data.visitor;
          loader.hide();
          switch (status) {
            case 200:
                this.currentSelectedVisitor = visitor;
                this.updateTabsUi();
            break;

            case 500:
              this.$toast.warning(res.data.original.msg);
            break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    handleLoadVisitoryDataToFormComponent(selectedVisitor){
      if(selectedVisitor){
        this.fetchOpsDetails(selectedVisitor.uuid);
        
      }
    },  

    updateTabsUi(){
      this.generalInfosSetUp = Boolean(this.currentSelectedVisitor.general_infos_setup);
      this.scopeSetUp = Boolean(this.currentSelectedVisitor.scope_setup);
      this.financeInfosSetUp = Boolean(this.currentSelectedVisitor.financial_infos_setup);
      this.priceListInfosSetUp = Boolean(this.currentSelectedVisitor.pricelist_infos_setup);
      this.synopticsInfosSetUp = Boolean(this.currentSelectedVisitor.synptics_infos_setup);
    },

    handleGeneralInfoStepSavedEvent(visitor){

      this.currentSelectedVisitor = visitor;

      this.generalInfosSetUp = true;
      // refresh contracts list
      this.shouldRefreshCompanyTable = true;
      // jump to next tab
      this.formStep = 1;
    },

  }
    
}
</script>
<template>
    <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste Employees</div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class="table-responsive mb-0 shadow">
                 <dataset v-slot="{ ds }" :ds-data="List_Employees" >
                  <div class="row">
                    <div class="col-md-6 mb-2 mb-md-0">
                    </div>
                    <div class="col-md-6">
                      <dataset-search ds-search-placeholder="Search..." />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="">
                        <table class="table table-hover d-md-table">
                          <thead>
                            <tr>
                              <th v-for="(th) in cols" :key="th.field">
                                {{ th.name }} 
                              </th>
                            </tr>
                          </thead>
                          <dataset-item tag="tbody">
                            <template #default="{ row, rowIndex }">
                              <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                                <td>{{ row.full_name }}</td>
                                <td>{{ row.employee_number }}</td>
                                <td>{{ row.post }}</td>
                                <td>{{ row.structure_name }}</td>
                              </tr>
                            </template>
                          </dataset-item>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                      <dataset-show :ds-show-entries="5" />
                    <!-- <dataset-info class="mb-2 mb-md-0" /> -->
                    <dataset-pager />
                  </div>
                </dataset>

                <!-- <table class="table table-centered table-nowrap"> -->
                <!--   <v-table
                    class="table table-hover"
                    :data="List_Employees"
                    selectionMode="single"
                    selectedClass="table-info"
                    @selectionChanged="selectedRows = $event"
                  >
                  <thead slot="head" class="thead-light">
                    <tr>
                      <th>Nom de visiteur</th>
                      <th>Carte d'identitie</th>
                      <th>Fonction</th>
                      <th>Entreprise</th>
                    </tr>
                  </thead>
                  <tbody slot="body" slot-scope="{displayData}">
                    <v-tr v-for="row in displayData" :key="row.id" :row="row">
                      <td>
                        {{ row.full_name }}
                      </td>
                      <td>{{ row.identity_card_number }}</td>
                      <td>{{ row.function }}</td>
                      <td>{{ row.company }}</td>
                    </v-tr>
                  </tbody>
                </v-table> -->
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

        <div class="card mt-3">
            <Intraform :base="selectedRows"  :visitor="currentSelectedVisitor" @refrechTable="refrechTable=$event" :selectedVisitor="selectedVisitor"/>
        </div>
      </div>
    </div>
    </Layout>
</template>